// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-all-news-all-news-js": () => import("./../../../src/components/pages/allNews/AllNews.js" /* webpackChunkName: "component---src-components-pages-all-news-all-news-js" */),
  "component---src-components-pages-news-page-single-news-page-js": () => import("./../../../src/components/pages/newsPage/SingleNewsPage.js" /* webpackChunkName: "component---src-components-pages-news-page-single-news-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-android-developer-js": () => import("./../../../src/pages/careers/android_developer.js" /* webpackChunkName: "component---src-pages-careers-android-developer-js" */),
  "component---src-pages-careers-dev-ops-js": () => import("./../../../src/pages/careers/dev_ops.js" /* webpackChunkName: "component---src-pages-careers-dev-ops-js" */),
  "component---src-pages-careers-digital-marketing-specialist-js": () => import("./../../../src/pages/careers/digital_marketing_specialist.js" /* webpackChunkName: "component---src-pages-careers-digital-marketing-specialist-js" */),
  "component---src-pages-careers-international-business-development-manager-direct-operations-js": () => import("./../../../src/pages/careers/international_business_development_manager_direct_operations.js" /* webpackChunkName: "component---src-pages-careers-international-business-development-manager-direct-operations-js" */),
  "component---src-pages-careers-international-business-development-manager-indirect-oem-operations-js": () => import("./../../../src/pages/careers/international_business_development_manager_indirect_oem_operations.js" /* webpackChunkName: "component---src-pages-careers-international-business-development-manager-indirect-oem-operations-js" */),
  "component---src-pages-careers-ios-developer-js": () => import("./../../../src/pages/careers/ios_developer.js" /* webpackChunkName: "component---src-pages-careers-ios-developer-js" */),
  "component---src-pages-careers-javascript-developer-js": () => import("./../../../src/pages/careers/javascript_developer.js" /* webpackChunkName: "component---src-pages-careers-javascript-developer-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-python-developer-js": () => import("./../../../src/pages/careers/python_developer.js" /* webpackChunkName: "component---src-pages-careers-python-developer-js" */),
  "component---src-pages-careers-sales-admin-js": () => import("./../../../src/pages/careers/sales_admin.js" /* webpackChunkName: "component---src-pages-careers-sales-admin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-settings-js": () => import("./../../../src/pages/cookie_settings.js" /* webpackChunkName: "component---src-pages-cookie-settings-js" */),
  "component---src-pages-dcode-js": () => import("./../../../src/pages/dcode.js" /* webpackChunkName: "component---src-pages-dcode-js" */),
  "component---src-pages-eudr-js": () => import("./../../../src/pages/eudr.js" /* webpackChunkName: "component---src-pages-eudr-js" */),
  "component---src-pages-european-funds-js": () => import("./../../../src/pages/european_funds.js" /* webpackChunkName: "component---src-pages-european-funds-js" */),
  "component---src-pages-forestry-explore-js": () => import("./../../../src/pages/forestry/explore.js" /* webpackChunkName: "component---src-pages-forestry-explore-js" */),
  "component---src-pages-forestry-js": () => import("./../../../src/pages/forestry.js" /* webpackChunkName: "component---src-pages-forestry-js" */),
  "component---src-pages-forestry-markings-gallery-js": () => import("./../../../src/pages/forestry/markings_gallery.js" /* webpackChunkName: "component---src-pages-forestry-markings-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspirations-about-js": () => import("./../../../src/pages/inspirations/about.js" /* webpackChunkName: "component---src-pages-inspirations-about-js" */),
  "component---src-pages-inspirations-cars-js": () => import("./../../../src/pages/inspirations/cars.js" /* webpackChunkName: "component---src-pages-inspirations-cars-js" */),
  "component---src-pages-inspirations-dating-sites-js": () => import("./../../../src/pages/inspirations/dating_sites.js" /* webpackChunkName: "component---src-pages-inspirations-dating-sites-js" */),
  "component---src-pages-inspirations-ecommerce-js": () => import("./../../../src/pages/inspirations/ecommerce.js" /* webpackChunkName: "component---src-pages-inspirations-ecommerce-js" */),
  "component---src-pages-inspirations-js": () => import("./../../../src/pages/inspirations.js" /* webpackChunkName: "component---src-pages-inspirations-js" */),
  "component---src-pages-inspirations-premium-brands-js": () => import("./../../../src/pages/inspirations/premium_brands.js" /* webpackChunkName: "component---src-pages-inspirations-premium-brands-js" */),
  "component---src-pages-inspirations-real-estate-js": () => import("./../../../src/pages/inspirations/real_estate.js" /* webpackChunkName: "component---src-pages-inspirations-real-estate-js" */),
  "component---src-pages-inspirations-restoration-js": () => import("./../../../src/pages/inspirations/restoration.js" /* webpackChunkName: "component---src-pages-inspirations-restoration-js" */),
  "component---src-pages-inspirations-sharing-js": () => import("./../../../src/pages/inspirations/sharing.js" /* webpackChunkName: "component---src-pages-inspirations-sharing-js" */),
  "component---src-pages-inspirations-web-publishing-js": () => import("./../../../src/pages/inspirations/web_publishing.js" /* webpackChunkName: "component---src-pages-inspirations-web-publishing-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-legal-documents-js": () => import("./../../../src/pages/legal_documents.js" /* webpackChunkName: "component---src-pages-legal-documents-js" */),
  "component---src-pages-offering-dcode-apply-and-read-js": () => import("./../../../src/pages/offering/dcode_apply_and_read.js" /* webpackChunkName: "component---src-pages-offering-dcode-apply-and-read-js" */),
  "component---src-pages-offering-js": () => import("./../../../src/pages/offering.js" /* webpackChunkName: "component---src-pages-offering-js" */),
  "component---src-pages-offering-timber-id-authentication-platform-js": () => import("./../../../src/pages/offering/timberID_authentication_platform.js" /* webpackChunkName: "component---src-pages-offering-timber-id-authentication-platform-js" */),
  "component---src-pages-offering-timber-id-eudr-platform-js": () => import("./../../../src/pages/offering/timberID_EUDR_platform.js" /* webpackChunkName: "component---src-pages-offering-timber-id-eudr-platform-js" */),
  "component---src-pages-optiwai-js": () => import("./../../../src/pages/optiwai.js" /* webpackChunkName: "component---src-pages-optiwai-js" */),
  "component---src-pages-optiwaiplus-about-js": () => import("./../../../src/pages/optiwaiplus/about.js" /* webpackChunkName: "component---src-pages-optiwaiplus-about-js" */),
  "component---src-pages-optiwaiplus-campaign-js": () => import("./../../../src/pages/optiwaiplus/campaign.js" /* webpackChunkName: "component---src-pages-optiwaiplus-campaign-js" */),
  "component---src-pages-optiwaiplus-explore-js": () => import("./../../../src/pages/optiwaiplus/explore.js" /* webpackChunkName: "component---src-pages-optiwaiplus-explore-js" */),
  "component---src-pages-optiwaiplus-integrations-js": () => import("./../../../src/pages/optiwaiplus/integrations.js" /* webpackChunkName: "component---src-pages-optiwaiplus-integrations-js" */),
  "component---src-pages-optiwaiplus-js": () => import("./../../../src/pages/optiwaiplus.js" /* webpackChunkName: "component---src-pages-optiwaiplus-js" */),
  "component---src-pages-optiwaiplus-pricing-js": () => import("./../../../src/pages/optiwaiplus/pricing.js" /* webpackChunkName: "component---src-pages-optiwaiplus-pricing-js" */),
  "component---src-pages-optiwaiplus-user-guide-js": () => import("./../../../src/pages/optiwaiplus/user_guide.js" /* webpackChunkName: "component---src-pages-optiwaiplus-user-guide-js" */),
  "component---src-pages-partnership-js": () => import("./../../../src/pages/partnership.js" /* webpackChunkName: "component---src-pages-partnership-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-realestate-js": () => import("./../../../src/pages/realestate.js" /* webpackChunkName: "component---src-pages-realestate-js" */),
  "component---src-pages-sawmill-board-js": () => import("./../../../src/pages/sawmill/board.js" /* webpackChunkName: "component---src-pages-sawmill-board-js" */),
  "component---src-pages-sawmill-entry-data-js": () => import("./../../../src/pages/sawmill/entry_data.js" /* webpackChunkName: "component---src-pages-sawmill-entry-data-js" */),
  "component---src-pages-sawmill-inventory-js": () => import("./../../../src/pages/sawmill/inventory.js" /* webpackChunkName: "component---src-pages-sawmill-inventory-js" */),
  "component---src-pages-sawmill-js": () => import("./../../../src/pages/sawmill.js" /* webpackChunkName: "component---src-pages-sawmill-js" */),
  "component---src-pages-solutions-dma-js": () => import("./../../../src/pages/solutions/dma.js" /* webpackChunkName: "component---src-pages-solutions-dma-js" */),
  "component---src-pages-solutions-dmh-js": () => import("./../../../src/pages/solutions/dmh.js" /* webpackChunkName: "component---src-pages-solutions-dmh-js" */),
  "component---src-pages-solutions-edg-js": () => import("./../../../src/pages/solutions/edg.js" /* webpackChunkName: "component---src-pages-solutions-edg-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-solutions-mobile-apps-dcode-gallery-js": () => import("./../../../src/pages/solutions/mobile_apps/dcode_gallery.js" /* webpackChunkName: "component---src-pages-solutions-mobile-apps-dcode-gallery-js" */),
  "component---src-pages-solutions-mobile-apps-js": () => import("./../../../src/pages/solutions/mobile_apps.js" /* webpackChunkName: "component---src-pages-solutions-mobile-apps-js" */),
  "component---src-pages-solutions-odr-js": () => import("./../../../src/pages/solutions/odr.js" /* webpackChunkName: "component---src-pages-solutions-odr-js" */),
  "component---src-pages-solutions-saas-js": () => import("./../../../src/pages/solutions/saas.js" /* webpackChunkName: "component---src-pages-solutions-saas-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-technology-dma-s-a-automated-js": () => import("./../../../src/pages/technology/dma_s_a_automated.js" /* webpackChunkName: "component---src-pages-technology-dma-s-a-automated-js" */),
  "component---src-pages-technology-dma-s-automated-js": () => import("./../../../src/pages/technology/dma_s_automated.js" /* webpackChunkName: "component---src-pages-technology-dma-s-automated-js" */),
  "component---src-pages-technology-dmh-js": () => import("./../../../src/pages/technology/dmh.js" /* webpackChunkName: "component---src-pages-technology-dmh-js" */),
  "component---src-pages-technology-dms-js": () => import("./../../../src/pages/technology/dms.js" /* webpackChunkName: "component---src-pages-technology-dms-js" */),
  "component---src-pages-technology-edg-js": () => import("./../../../src/pages/technology/edg.js" /* webpackChunkName: "component---src-pages-technology-edg-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-technology-mobile-apps-dcode-gallery-js": () => import("./../../../src/pages/technology/mobile_apps/dcode_gallery.js" /* webpackChunkName: "component---src-pages-technology-mobile-apps-dcode-gallery-js" */),
  "component---src-pages-technology-mobile-apps-js": () => import("./../../../src/pages/technology/mobile_apps.js" /* webpackChunkName: "component---src-pages-technology-mobile-apps-js" */),
  "component---src-pages-technology-odr-js": () => import("./../../../src/pages/technology/odr.js" /* webpackChunkName: "component---src-pages-technology-odr-js" */),
  "component---src-pages-traceability-at-the-sawmill-js": () => import("./../../../src/pages/traceability/at_the_sawmill.js" /* webpackChunkName: "component---src-pages-traceability-at-the-sawmill-js" */),
  "component---src-pages-traceability-forest-js": () => import("./../../../src/pages/traceability/forest.js" /* webpackChunkName: "component---src-pages-traceability-forest-js" */),
  "component---src-pages-traceability-forest-street-js": () => import("./../../../src/pages/traceability/forest_street.js" /* webpackChunkName: "component---src-pages-traceability-forest-street-js" */),
  "component---src-pages-traceability-js": () => import("./../../../src/pages/traceability.js" /* webpackChunkName: "component---src-pages-traceability-js" */),
  "component---src-pages-traceability-logistics-js": () => import("./../../../src/pages/traceability/logistics.js" /* webpackChunkName: "component---src-pages-traceability-logistics-js" */),
  "component---src-pages-traceability-sawmill-trader-js": () => import("./../../../src/pages/traceability/sawmill_trader.js" /* webpackChunkName: "component---src-pages-traceability-sawmill-trader-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use_cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-pages-user-guide-js": () => import("./../../../src/pages/user_guide.js" /* webpackChunkName: "component---src-pages-user-guide-js" */)
}

